import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Logo from './Logo';

const NavStyles = styled.nav`
  margin-bottom: 3rem;
  .logo {
    transform: translateY(5%);
  }
  ul {
    margin: 0;
    margin-left: -7%;
    margin-right: -7%;
    padding: 0;
    text-align: center;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr auto 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
    margin-top: -35px;
  }
  
`;

export default function Nav () {
  return (
    <NavStyles>
      <ul>
        <li>

        </li>
        <li>

        </li>
        <li>
          <Link to="/">
            <Logo />
          </Link>
        </li>
        <li>

        </li>
        <li>

        </li>
      </ul>
    </NavStyles>
  );
}
