import React from 'react';
import Nav from '../components/Nav';
import Layout from '../components/Layout'

export default function SlicemastersPage() {
  return (
    <>
      <p>Hey! I'm the Slicemasters page</p>
    </>
  );
}