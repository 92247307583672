
import React from 'react';
import styled from 'styled-components';

const LogoStyles = styled.div`
  display: flex;
  .inner {
    flex: 1;
    align-content: center;
  }
  width: 114%;
  margin-left: -7%;
  margin-top: -3%;

  @media (max-width: 520px) {
  margin-top: -7%;
  }
  
`;

export default function Logo () {

  return (
    <LogoStyles className="logo">
      <div className="inner">
        {/* <img src="https://cdn.sanity.io/images/pda0uygu/production/010d7bc442b389fc4407e8793c0656b38527d472-946x558.png?w=473" /> */}
        <img src="https://cdn.sanity.io/images/pda0uygu/production/292f9d615852cf12015ca74ba91a425249a6cb5b-2000x1002.jpg?w=1000" />
      </div>
    </LogoStyles>
  );
}